import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

import styles from "./tg-date-dropdown.module.scss";
import TGIcon from "../tg-icon";
import { t } from "i18next";
import moment from "moment";

export interface DateDropdownProps {
  label: string;
  isError?: boolean;
  errorLabel?: string;
 iaValid?:string;
 onDateChange:(date: string, month:string, year:string)=> void;
customClassName?:string;
 isEnroll?:boolean;
 isDesktop?:boolean
 savedDob?:string;
 disables?:boolean;
}

const TGDateDropdown: React.FC<DateDropdownProps> = (props) => {
  // Initialize state variables for date, month, and year
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [disablePropDob,setDisablePropDob] = useState(true); 
  useEffect(()=>{
    if(props.savedDob && disablePropDob){
      const[year,month,date] = props.savedDob.split('-');
      setDate(date.toString());
      setMonth(month.toString());
      setYear(year.toString());
    }
  },[props.savedDob])
  // Function to handle changes in dropdown values
  const handleDateChange = (e: any) => {
    setDisablePropDob(false);
    setDate(e.target.value);
    const monthValue = handleMonthFormat(month);
    props.onDateChange(e.target.value, monthValue, year);
  };

  const monthNames: string[] = [
    t("label_dob_calendar_january"),
    t("label_dob_calendar_february"),
    t("label_dob_calendar_march"),
    t("label_dob_calendar_april"),
    t("label_dob_calendar_may"),
    t("label_dob_calendar_june"),
    t("label_dob_calendar_july"),
    t("label_dob_calendar_august"),
    t("label_dob_calendar_september"),
    t("label_dob_calendar_october"),
    t("label_dob_calendar_november"),
    t("label_dob_calendar_december"),
  ];

  const handleMonthFormat = (month: string) => {
    const monthValue = monthNames.findIndex((x: any) => x === month);
    let correctMonthValue = monthValue + 1;
    let formattedMonthValue = "";

    if (correctMonthValue < 10) {
      formattedMonthValue = `0${correctMonthValue.toString()}`;
    } else {
      formattedMonthValue = correctMonthValue.toString();
    }
    return formattedMonthValue;
  };

  const handleMonthChange = (e: any) => {
    setDisablePropDob(false);
    const monthValue = handleMonthFormat(e.target.value);
    setMonth(e.target.value);
    props.onDateChange(date, monthValue, year);
  };

  const handleYearChange = (e: any) => {
    setDisablePropDob(false);
    setYear(e.target.value);
    const monthValue = handleMonthFormat(month);
    props.onDateChange(date, monthValue, e.target.value);
  };

  // Generate options for date, month, and year
  const dates = Array.from({ length: 31 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const months = Array.from(monthNames);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1899 }, (_, i) =>
    String(currentYear - i)
  );

  return (
    <Form.Group
      controlId="exampleForm.ControlInput1"
      className={`${props.isEnroll && props.isDesktop? 
        props.customClassName == 'newsletterSubscribe' ? styles.newsletterFormGroup :  styles.customFormGroupEnroll : styles.customFormGroup}`}
    >
      <Form.Label className={`${!props.isEnroll ? styles.inputLabel : styles.enrollLabel }`}>{props.label}</Form.Label>
      <div className={styles.datedropdownWrapper}>
        <div className={`${styles.dateDropDownOptions}`}>
          <InputGroup>
            <Form.Select
              value={date}
              onChange={handleDateChange}
              disabled={props.disables}
              className={`${(date == "" && props.isError)|| props.isError && props.isEnroll ? styles.dateError: styles.date}`}
            >
              <option disabled value="">
                {t("label_dd")}
              </option>
              {dates.map((date) => (
                <option key={date} value={date}>
                  {date}
                </option>
              ))}
            </Form.Select>
            {props.isError && !date && (
              <div className={styles.errorIcon}>
                <TGIcon icon="error-icon" fillColor="" size="16" />
              </div>
            )}
          </InputGroup>
          <InputGroup className={styles.dateLabel}>
            <Form.Select
              value={month}
              onChange={handleMonthChange}
              disabled={props.disables}
              className={`${styles.Month} ${(month == "" && props.isError) || props.isError && props.isEnroll ? styles.applyBorder: ""}`}
            >
              <option disabled value="">
                {t("label_mm")}
              </option>
              {months.map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </Form.Select>
            {props.isError && !month && (
              <div className={styles.errorIcon}>
                <TGIcon icon="error-icon" fillColor="" size="16" />
              </div>
            )}
          </InputGroup>
          <InputGroup>
            <Form.Select
              value={year}
              onChange={handleYearChange}
              disabled={props.disables}
              className={`${styles.year} ${(year == "" && props.isError) || props.isError && props.isEnroll ? styles.applyBorderYear: ""}`}
            >
              <option disabled value="">
                {t("label_yy")}
              </option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
            {props.isError && !year && (
              <div className={styles.errorIcon}>
                <TGIcon icon="error-icon" fillColor="" size="16" />
              </div>
            )}
          </InputGroup>
        </div>
      </div>
      {props.isError && (
        <Form.Label className={styles.errorLabel}>
          {props.errorLabel}
        </Form.Label>
      )}
    </Form.Group>
  );
};

export default TGDateDropdown;
