import React, { useState } from "react";
import { Accordion, AccordionCollapse, ListGroup } from "react-bootstrap";
import styles from "./tg-expandable-list.module.scss";
import TGIcon from "../tg-icon";
import { isUserLoggedIn } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  expandableList: any;
  showLevel2NavArrow?: boolean;
  level2NavArrowClick: (name: string, subNavigationDetails: []) => void;
  onClose: () => void;
}

const TGExpandableList = (props: Props) => {
  console.log("expandableList", props.expandableList);
  //const handleShowHide = () => setisExpandableOpen(!isExpandable);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const handleHeaderNavigationClick = (url: string) => {
    if (url?.includes("flightstatus")) {
      navigate(`${i18n?.language}/flightstatus`);
    } else if (url?.includes("flightschedule")) {
      navigate(`${i18n?.language}/flightschedule`);
    } else {
      window.open(url, "_SELF");
    }
    props.onClose();
  };

  return (
    <Accordion
      defaultActiveKey="0"
      data-testid="expandable-list-container"
      className={styles["expandable-list-container"]}
    >
      {props.expandableList.map((x: any, index: number) => (
        <Accordion.Item
          eventKey={index.toString()}
          key={index}
          className={styles["expandable-list-head-item"]}
        >
          <Accordion.Header className={styles["expandable-list-header"]}>
            {x.mainHeaderName}
          </Accordion.Header>
          <Accordion.Body className={styles["expandable-list-body"]}>
            <ListGroup className={styles["expandable-list-wrapper"]}>
              {isUserLoggedIn() && x?.mainHeaderGroupLoggedIn?.length > 0 ? (
                <>
                  {x?.mainHeaderGroupLoggedIn?.length > 0 &&
                    x.mainHeaderGroupLoggedIn?.map(
                      (hg: any, hgindex: number) => (
                        <>
                          {hg?.mainHeaderNavigation?.length > 0 &&
                            hg?.mainHeaderNavigation?.map(
                              (z: any, zindes: number) =>
                                !z.showMap &&
                                !z.showCard && (
                                  <ListGroup.Item
                                    key={zindes}
                                    className={styles["expandable-list-item"]}
                                  >
                                    <span
                                      onClick={() =>
                                        handleHeaderNavigationClick(
                                          z?.mainHeaderNavigationUrl
                                        )
                                      }
                                    >
                                      {z?.mainHeaderNavigationName}
                                    </span>
                                    {props.showLevel2NavArrow &&
                                      !z?.hideMobileViewArrow &&
                                      z?.mainHeaderSubNavigation?.length >
                                        0 && (
                                        <div
                                          className={
                                            styles["expandable-list-icon"]
                                          }
                                          onClick={() =>
                                            props?.level2NavArrowClick(
                                              z?.mainHeaderNavigationName,
                                              z?.mainHeaderSubNavigation
                                            )
                                          }
                                        />
                                      )}
                                  </ListGroup.Item>
                                )
                            )}
                        </>
                      )
                    )}
                </>
              ) : (
                <>
                  {x?.mainHeaderGroup?.length > 0 &&
                    x.mainHeaderGroup?.map((hg: any, hgindex: number) => (
                      <>
                        {hg?.mainHeaderNavigation?.length > 0 &&
                          hg?.mainHeaderNavigation?.map(
                            (z: any, zindes: number) =>
                              !z.showMap &&
                              !z.showCard && (
                                <ListGroup.Item
                                  key={zindes}
                                  className={styles["expandable-list-item"]}
                                >
                                  <span
                                    onClick={() =>
                                      handleHeaderNavigationClick(
                                        z?.mainHeaderNavigationUrl
                                      )
                                    }
                                  >
                                    {z?.mainHeaderNavigationName}
                                  </span>
                                  {props.showLevel2NavArrow &&
                                    !z?.hideMobileViewArrow &&
                                    z?.mainHeaderSubNavigation?.length > 0 && (
                                      <div
                                        className={
                                          styles["expandable-list-icon"]
                                        }
                                        onClick={() =>
                                          props?.level2NavArrowClick(
                                            z?.mainHeaderNavigationName,
                                            z?.mainHeaderSubNavigation
                                          )
                                        }
                                      />
                                    )}
                                </ListGroup.Item>
                              )
                          )}
                      </>
                    ))}
                </>
              )}
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default TGExpandableList;

/// To Do: Need to move this to new file
export const ExpandableListFirstLevelView = (props: Props) => {
  const { i18n } = useTranslation();
  console.log("expandableListFirst", props.expandableList);

  const handleHeaderNavigationClick = (url: string) => {
    if (url.includes("myprofile?")) {
      const query = url.split("?")[1];
      window.open(`/${i18n.language}/myprofile?${query}`, "_SELF");
    } else {
      window.open(url, "_SELF");
    }
  };

  const locationArr =
    props.expandableList?.[0]?.mainHeaderSubNavigationId === 1011
      ? [
          ...props.expandableList?.[0]?.mainHeaderSubNavigationSubCategory,
          ...props.expandableList?.[1]?.mainHeaderSubNavigationSubCategory,
        ]
      : [];
  return props.expandableList?.[0]?.mainHeaderSubNavigationId !== 1011 ? (
    <div className={styles["expandable-list-body"]}>
      {props.expandableList?.map((x: any, index: number) => (
        <ListGroup key={index} className={styles["expandable-list-wrapper"]}>
          <ListGroup.Item
            key={index}
            className={styles["expandable-list-item"]}
            id={index.toString()}
          >
            <span
              onClick={() =>
                handleHeaderNavigationClick(x?.mainHeaderSubNavigationUrl)
              }
            >
              {x?.mainHeaderSubNavigationTitle}&nbsp;
              {x?.isExternalUrl && (
                <TGIcon
                  icon={"external-link"}
                  fillColor="#6A6D87"
                  size="16"
                ></TGIcon>
              )}
            </span>
          </ListGroup.Item>
        </ListGroup>
      ))}
    </div>
  ) : (
    <div className={styles["expandable-list-body"]}>
      {locationArr?.map((z: any, index) => (
        <ListGroup key={index} className={styles["expandable-list-wrapper"]}>
          <ListGroup.Item
            key={index}
            className={styles["expandable-list-item"]}
            id={index.toString()}
          >
            <span>{z.mainHeaderSubNavigationSubCategoryTitle}</span>
          </ListGroup.Item>
        </ListGroup>
      ))}
    </div>
  );
};
