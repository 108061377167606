import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./tg-custom-carousel.module.scss";
import TGIcon from "../../shared/tg-icon";
import { reverseJsonArray, setLocalStorageData } from "../../utils/helper";
import {
  BookFlightTab,
  BookFlightTabSections,
  CabinClass,
} from "../../utils/enum-helper";
import { useDispatch } from "react-redux";
import { updateSelectedCardData } from "../../OSCI/src/slice/bookingWidgetSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../utils/hook/useScreenSize";

interface Props {
  setFormattedSearchFlightData?: any;
  formattedSearchFlightData?: any;
  setSelectedItem?: any;
}

const TGCustomCarousel: React.FC<Props> = (props: Props) => {
  const sliderRef = useRef<Slider>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const totalSlides = props.formattedSearchFlightData.length;
  const screenSize = useScreenSize().deviceSize.width;
  const slidesToScroll = 2;
  let slidesToShow = 3;
  if (screenSize >= 768 && screenSize < 800) {
    slidesToShow = 2.15;
  } else if (screenSize >= 800 && screenSize < 850) {
    slidesToShow = 2.2;
  } else if (screenSize >= 850 && screenSize < 900) {
    slidesToShow = 2.25;
  } else if (screenSize >= 900 && screenSize < 992) {
    slidesToShow = 2.5;
  } else if (screenSize >= 992 && screenSize <= 1100) {
    slidesToShow = 2.75;
  } else {
    slidesToShow = 3;
  }
  const elementRef = useRef<any>(null);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    arrows: false,
    autoplay: false,
    initialSlide: 0,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    },
    customPaging: (i: number) => (
      <div className={styles.customDot}>
        {elementRef?.current?.childNodes?.length - 1 === i &&
        currentSlide >= totalSlides - slidesToShow ? (
          <svg
            width="20"
            height="6"
            viewBox="0 0 20 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="6" rx="3" fill="#684B9B" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="6"
            viewBox="0 0 6 6"
            fill="none"
          >
            <circle opacity="0.5" cx="3" cy="3" r="3" fill="#CACAD9" />
          </svg>
        )}
      </div>
    ),
    appendDots: (dots: React.ReactNode) => (
      <div className={styles.customDots}>
        <div
          onClick={() => sliderRef?.current?.slickPrev()}
          className="pointer"
        >
          <TGIcon
            icon="arrow-left"
            size="20px"
            fillColor={currentSlide === 0 ? "#CACAD9" : "#684B9B"}
          />
        </div>
        <ul ref={elementRef}>{dots}</ul>
        <div
          onClick={() => sliderRef?.current?.slickNext()}
          className="pointer"
        >
          <TGIcon
            icon="mini-profile-right-arrow"
            size=""
            fillColor={
              currentSlide >= totalSlides - slidesToShow ? "#CACAD9" : "#684B9B"
            }
          />
        </div>
      </div>
    ),

    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2.75,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.25,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2.2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2.15,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.1,
        },
      },
    ],
  };
  const reverseSearchData = reverseJsonArray(props.formattedSearchFlightData);

  const customCardComponent = (item: any, cardId: number) => {
    const handleRemoveCard = (
      e: React.MouseEvent<HTMLButtonElement>,
      index: any
    ) => {
      e.stopPropagation();
      let updatedCard = props.formattedSearchFlightData?.filter(
        (x: any, index: any) => x.id !== cardId
      );
      // This is to position the mobile swap button when the length of recent search card is empty.
      if (updatedCard.length === 0) {
        document.documentElement.style.setProperty(
          "--mobileSwapAlignment",
          "28%"
        );
      }
      props.setFormattedSearchFlightData(updatedCard);
      setLocalStorageData("recentSearch", updatedCard);
    };

    const handleCardSelection = (
      e: React.MouseEvent<HTMLDivElement>,
      cardData: any
    ) => {
      e.stopPropagation();
      if (cardData.type === BookFlightTabSections.RoundTrip) {
        props.setSelectedItem(BookFlightTab.Return);
      } else if (cardData.type === BookFlightTabSections.OneWay) {
        props.setSelectedItem(BookFlightTab.OneWay);
      } else {
        props.setSelectedItem(BookFlightTab.MultiCity);
      }

      dispatch(updateSelectedCardData({ cardData: cardData }));
    };
    const handleError = (
      event: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
      event.currentTarget.src = "../assets/recentsearchdefault.png";
    };

    return (
      <div
        className={`${totalSlides <= 2 && styles.cardStyle} ${
          totalSlides < 2 && styles["card-width"]
        } ${item.type === "Multi-city" && styles.cardStyleMultiCity} ${
          totalSlides > 2 && styles.cardStyleSlider
        }`}
        onClick={(e) => handleCardSelection(e, item)}
      >
        {item?.type !== "Multi-city" && (
          <div>
            <img
              src={item?.url}
              onError={handleError}
              className={styles.cardSearchImg}
              alt={`recent search card data ${item.id}`}
            />
          </div>
        )}
        <div>
          <div>
            <button
              onClick={(e) => handleRemoveCard(e, item.id)}
              aria-label="close button"
            >
              <TGIcon icon="x-circle" size={""} fillColor={"none"} />
            </button>
          </div>
          <div>
            {item?.type !== "Multi-city" ? (
              <div className={styles["card-airport-details"]}>
                <span>{item?.from?.airportCode}</span>
                <span>
                  <TGIcon icon="plane-icon" size={"16"} fillColor={""} />
                </span>
                <span>{item?.to?.airportCode}</span>
              </div>
            ) : (
              <span
                className={`${styles["multi-city-airport-code-wrapper"]} me-1`}
              >
                {item?.from?.airportCode}
              </span>
            )}
          </div>
          <div>
            {item?.return !== "" && (
              <div>
                {moment(item?.depart, "YYYY-MM-DD").format("DD MMM YYYY")} -{" "}
                {moment(item?.return, "YYYY-MM-DD").format("DD MMM YYYY")}
              </div>
            )}
            {item?.return === "" && (
              <div>
                {moment(item?.depart, "YYYY-MM-DD").format("DD MMM YYYY")}
              </div>
            )}
          </div>
          <div>{item?.type}</div>
          {item?.class === CabinClass.Economy && item?.count > 1 && (
            <div>
              {item?.count} {t("label_book_widget_passengers")} |{" "}
              {t("label_economy_and_economy_plus")}
            </div>
          )}
          {item?.class === CabinClass.Economy && item?.count === 1 && (
            <div>
              {item?.count} {t("label_passenger")} |{" "}
              {t("label_economy_and_economy_plus")}
            </div>
          )}
          {item?.class === CabinClass.Business && item?.count > 1 && (
            <div>
              {item?.count} {t("label_book_widget_passengers")} |{" "}
              {t("label_business_and_first")}
            </div>
          )}
          {item?.class === CabinClass.Business && item?.count === 1 && (
            <div>
              {item?.count} {t("label_passenger")} |{" "}
              {t("label_business_and_first")}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${styles["carousel-container"]} ${
        totalSlides < 3 && styles["carousel-flex"]
      }`}
    >
      {props.formattedSearchFlightData.length > 2 ? (
        <Slider ref={sliderRef} {...settings}>
          {reverseSearchData.map((item: any, cardIndex: number) => (
            <>{customCardComponent(item, item.id)}</>
          ))}
        </Slider>
      ) : (
        reverseSearchData.map((item: any, cardIndex: number) => (
          <>{customCardComponent(item, item.id)}</>
        ))
      )}
    </div>
  );
};

export default TGCustomCarousel;
