import React, { useEffect, useState } from "react";
import styles from "./tg-full-screen-error.module.scss";
import TGIcon from "../../shared/tg-icon";
import TGButton from "../../shared/tg-button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type IProps = {
  heading: string,
  message: string,
  buttonPrimaryText: string,
  buttonSecText: string,
  errorIcon: string,
  onClickPrimary: ()=>{},
  onClickSec: ()=>{},
}
const TGFullScreenError: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const {
    heading,
    message,
    buttonPrimaryText,
    buttonSecText,
    errorIcon,
    onClickPrimary,
    onClickSec,
  } = props;

  const handlePrimaryButtonSelection = () => {
    onClickPrimary();
  };

  const handleSecButtonSelection = () => {
    onClickSec();
  };

  return (
    <>
      <div className={styles["generic-error-container"]}>
        <div className={styles["error-dialog"]}>
          <TGIcon fillColor={"none"} icon={errorIcon || "error-icon-generic"} />
          <div className={styles["error-dialog-content"]}>
            <span className={styles["error-dialog-heading"]}>
              {heading || t("label_something_went_wrong")}
            </span>
            <span className={styles["error-dialog-message"]}>
              {message || t("error_generic_failed_desc")}
            </span>
          </div>
        </div>
        <div className={styles["generic-dialog-button-container"]}>
          <div className="d-flex gap-3 justify-content-center">
            <TGButton
              label={buttonSecText || t("button_generic_error_text")}
              variant="secondary"
              buttonSize="sm"
              customClass={styles["secBtn"]}
              onClick={() => {
                handleSecButtonSelection();
              }}
              disabled={false}
            />
            <TGButton
              label={buttonPrimaryText || t("label_try_again")}
              variant="primary"
              buttonSize="sm"
              customClass={styles["primaryBtn"]}
              onClick={() => {
                handlePrimaryButtonSelection();
              }}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TGFullScreenError;
