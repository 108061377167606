import React from "react";
import TGIcon from "../tg-icon";
import styles from "./tg-icon-label.module.scss";

interface Props {
  position: string;
  className?: string;
  iconClassName?: string;
  optionalClassName?:string;
  icon: string;
  fillColor?: string;
  size?: string;
  onClick?: (data:any) => void;
  label?: string;
}

const TGIconLabel: React.FC<Props> = (props: Props) => { 
  return (
    <div className={`${
      styles[props.optionalClassName ? props.optionalClassName : ""]
    }`} onClick ={props.onClick}>
      {props.position === "left" && (
        <span
          className={` ${
            styles[props.iconClassName ? props.iconClassName : ""]
          }`}
          
        >
          <TGIcon
            icon={props.icon ? props.icon : ''}
            fillColor={props.fillColor || ''}
            size={props.size || '32'}
          />
        </span>
      )}
      <span className={`${styles.tgIconLabelStyle} ${styles[props.className ? props.className : ""]}`}>
        {props.label}
      </span>
      {props.position === "right" && (
        <span
          className={` ${
            styles[props.iconClassName ? props.iconClassName : ""]
          }`}
        >
          <TGIcon
            icon={props.icon ? props.icon : ''}
            fillColor={props.fillColor || ''}
            size={props.size || '32'}
          />
        </span>
      )}
    </div>
  );
};

export default TGIconLabel;
