import React, { useEffect, useState } from "react";
import styles from "./tg-generic-error-dialog.module.scss";
import TGModal from "../../shared/tg-modal";
import TGIcon from "../../shared/tg-icon";
import TGButton from "../../shared/tg-button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  clearError,
  setButtonAction,
} from "../../OSCI/src/slice/errorModalSlice";
import { logoutRequest } from "../../OSCI/src/slice/logOutSlice";
import LoginWidget from "../login-widget";
import { useNavigate } from "react-router-dom";

const TGGenericErrorDialog: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showErrorModal, errorDetails } = useSelector(
    (state: RootState) => state?.osciReducer?.errorModal
  );
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const {
    heading,
    message,
    buttonText,
    errCode,
    isLoginReq,
    isSecondaryButton,
    buttonTextSecondary,
    messageIcon,
    type,
  } = errorDetails;
  useEffect(() => {
    if (showErrorModal) {
      document.documentElement.style.setProperty(`--body-overflow`, `hidden`);
    } else {
      document.documentElement.style.setProperty(`--body-overflow`, `auto`);
    }
    return () => {
      document.documentElement.style.setProperty(`--body-overflow`, `auto`);
    };
  }, [showErrorModal]);
  if (!showErrorModal) return null;

  const handleClearData = () => {
    dispatch(clearError());
    if (errCode === 401) {
      sessionStorage.clear();
      dispatch(logoutRequest());
    }
    setShowLoginPopup(false);
  };
  const handleCloseModal = () => {
    handleClearData();
    if (type === "timer-details") {
      dispatch(setButtonAction(false));
    } else {
      navigate("/");
    }
  };

  const handleButtonSelection = () => {
    if (isLoginReq) {
      setShowLoginPopup(true);
    } else {
      if (type === "timer-details") {
        dispatch(setButtonAction(true));
      } else if (type === "expired-timer-details") {
        dispatch(clearError());
        navigate("/");
      }
      handleClearData();
    }
  };

  const handleSecondaryButtonSelection = () => {
    if (type === "timer-details") {
      dispatch(setButtonAction(false));
    } else if (type === "expired-timer-details") {
      dispatch(clearError());
      navigate("/");
    }
  };

  return (
    <>
      {!showLoginPopup ? (
        <TGModal
          show={showErrorModal}
          handleClose={type ? handleSecondaryButtonSelection : handleCloseModal}
          buttonLabel="Ok"
          customModalClass={styles["generic-error-modal"]}
          customOffCanvasClass={styles["generic-offcanvas-error-modal"]}
          centered={true}
          backdrop="static"
        >
          <div className={styles["generic-dialog-message-container"]}>
            <div
              className={`${styles["generic-dialog"]} ${
                type && styles["custom-modal-padding"]
              }`}
            >
              <TGIcon
                fillColor={"none"}
                icon={messageIcon ? messageIcon : "duplicate-nominee-icon"}
              />
              <div className={styles["generic-dialog-content"]}>
                <span className={styles["generic-dialog-heading"]}>
                  {heading}
                </span>
                <span className={styles["generic-dialog-message"]}>
                  {message}
                </span>
              </div>
            </div>
            <div className={styles["generic-dialog-button-container"]}>
              {isSecondaryButton && (
                <div>
                  <TGButton
                    label={buttonTextSecondary}
                    variant="secondary"
                    buttonSize="sm"
                    customClass="bookingButtonCheckIn"
                    onClick={() => {
                      handleSecondaryButtonSelection();
                    }}
                    disabled={false}
                  />
                </div>
              )}
              <div>
                <TGButton
                  label={buttonText}
                  variant="primary"
                  buttonSize="sm"
                  customClass="bookingButtonCheckIn"
                  onClick={() => {
                    handleButtonSelection();
                  }}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </TGModal>
      ) : (
        <LoginWidget
          onHidePanel={() => window.location.replace(window.location.origin)}
          loginName={() => {}}
          handlePanelShow={() => {}}
          handleSuccess={() => handleClearData()}
        />
      )}
    </>
  );
};

export default TGGenericErrorDialog;
