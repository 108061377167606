import React, { useEffect } from "react";
import { useState, ChangeEvent } from 'react';
import Form from 'react-bootstrap/Form';
import styles from './tg-checkbox.module.scss'
interface Props {
    label?: string;
    secondaryLabel?:string
    labelLink?:string;
    htmlContent?:any;
    labelLinkSeconday?:string;
    linkURL?:string;
    checked?: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    className: string;
    customLabel:string;
    checkref?:any;
    savedValue?:string;
    isHtmlContent?:boolean;
    disabled?: boolean;
}

const TGCheckBox = (props: Props) => {
  const[checked, setChecked]= useState<boolean>(false);
  useEffect(()=>{
    setChecked(props.checked)
  })

  return (
    <Form.Check  className={`${styles[props.className]}`}>
      <Form.Check.Input
        type="checkbox"
        checked={checked}
        onChange={props.onChange}
        ref={props?.checkref}
        disabled={props.disabled}
      />
  <Form.Check.Label className={`${styles[props.customLabel]}`}>{props.isHtmlContent ? <span  dangerouslySetInnerHTML={{__html:props.htmlContent}}></span> : props.label}</Form.Check.Label>
    </Form.Check> 
    
  );
};

export default TGCheckBox;