import React from "react"
import { Container } from "react-bootstrap"
import tgCardSubStyles from './tg-card-sub.module.scss';
import TGIcon from "../tg-icon";
interface TgCardSubProps{
   iconName: string;
   fillColor?: string;
   size?:string;
   titleText?: string;
   subText?:string;
}
export const TGCardSub =(props: TgCardSubProps)=>{
    return(
        <div className={tgCardSubStyles.SubContainer}>
            <div className={tgCardSubStyles.TGSubICon}>
            <TGIcon icon ={props.iconName} fillColor={props.fillColor || ''} size={props.size || ''} />
            </div>
          <Container className={tgCardSubStyles.SubTextCOntainer}>
             <span className={tgCardSubStyles.SubTitleText}>{props.titleText}</span>
             <span className={tgCardSubStyles.SubText}>{props.subText}</span>
          </Container>
        </div>
    )

}