// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.DJcEbcaoIMbNP_IXZG9Z {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.MZNgGOYOYDcId0GutVK2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.CDGroomky05WS3dLPxHe {
  align-self: stretch;
  color: var(--gray-text, #6A6D87);
  /* Body2/Body 2 — Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
}

.g4NMaEmCPEVNMVnnjv0k {
  align-self: stretch;
  color: var(--gray-text-strong, #2C3059);
  /* Body1/Body 1 — Bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 25.6px */
}

.C0qTM_U2wJhmys0L5Uvt {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 1.458px 2.604px 0.625px 3.125px;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/shared/tg-card-sub/tg-card-sub.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,aAAA;EACJ,mBAAA;EACA,SAAA;EACA,mBAAA;AAEA;;AACA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;EACA,WAAA;AAEJ;;AAAA;EACI,mBAAA;EACA,gCAAA;EAEJ,2BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AAEA;;AAAA;EACI,mBAAA;EACA,uCAAA;EAEJ,wBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AAEA;;AAAA;EACI,aAAA;EACJ,WAAA;EACA,YAAA;EACA,wCAAA;EACA,uBAAA;EACA,mBAAA;AAGA","sourcesContent":[".SubContainer{\n    display: flex;\nalign-items: center;\ngap: 12px;\nalign-self: stretch;\n\n}\n.SubTextCOntainer{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n    flex: 1 0 0;\n}\n.SubTitleText{\n    align-self: stretch;\n    color: var(--gray-text, #6A6D87);\n\n/* Body2/Body 2 — Regular */\nfont-family: Inter;\nfont-size: 14px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 160%; /* 22.4px */\n}\n.SubText{\n    align-self: stretch;\n    color: var(--gray-text-strong, #2C3059);\n\n/* Body1/Body 1 — Bold */\nfont-family: Inter;\nfont-size: 16px;\nfont-style: normal;\nfont-weight: 700;\nline-height: 160%; /* 25.6px */\n}\n.TGSubICon{\n    display: flex;\nwidth: 20px;\nheight: 20px;\npadding: 1.458px 2.604px 0.625px 3.125px;\njustify-content: center;\nalign-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SubContainer": `DJcEbcaoIMbNP_IXZG9Z`,
	"SubTextCOntainer": `MZNgGOYOYDcId0GutVK2`,
	"SubTitleText": `CDGroomky05WS3dLPxHe`,
	"SubText": `g4NMaEmCPEVNMVnnjv0k`,
	"TGSubICon": `C0qTM_U2wJhmys0L5Uvt`
};
export default ___CSS_LOADER_EXPORT___;
