// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xuw2lfgukOZfLptNmCbg {
  margin-bottom: 27px;
}

.eGb_L9HhgUsaCH3DsKJ0 {
  font-size: 36px;
  font-weight: 400;
  line-height: 43.2px;
  color: #2c3059;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}

.N_cnFU9cNqFfh2tKGlcg {
  font-size: 16px;
  font-weight: 700;
  line-height: 25.6px;
  color: #684b9b;
}`, "",{"version":3,"sources":["webpack://./src/shared/tg-head/tg-head.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,qBAAA;EACA,gBAAA;EACA,uBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AACF","sourcesContent":[".head-container {\n    margin-bottom: 27px;\n}\n\n.label-heading {\n  font-size: 36px;\n  font-weight: 400;\n  line-height: 43.2px;\n  color: #2c3059;\n  display: -webkit-box;\n  -webkit-line-clamp: 1;\n  -webkit-box-orient: vertical;\n  word-break: break-all;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.label-actionText {\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 25.6px;\n  color: #684b9b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head-container": `Xuw2lfgukOZfLptNmCbg`,
	"label-heading": `eGb_L9HhgUsaCH3DsKJ0`,
	"label-actionText": `N_cnFU9cNqFfh2tKGlcg`
};
export default ___CSS_LOADER_EXPORT___;
