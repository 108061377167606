import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./tg-head.module.scss";
import TGIcon from "../tg-icon";

interface Props {
  customClass?: string;
  label: string | undefined;
  actionText?: any;
  onClick?: () => void;
}

const TGHead = (props: Props) => {
  return (
    <Row className={`${styles["head-container"]} ${props.customClass}`}>
      <Col className="d-flex  justify-content-between align-items-center text-align-center">
        <span className={styles["label-heading"]}>{props.label}</span>
        <div className="d-flex  justify-content-center align-items-center">
          {props.actionText && (
            <>
              <span className={styles["label-actionText"]}>
                {props.actionText}
              </span>
              <TGIcon icon="arrow-right" fillColor="" size="24" />
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default TGHead;
