import React, { useState, useEffect, ReactNode, useRef } from "react";
import Carousel from "react-bootstrap/Carousel";
import styles from "./tg-carousel.module.scss";
import { Col, Row } from "react-bootstrap";
import TGButton from "../tg-button";
import { Link } from "react-router-dom";

interface IProps {
  variant: string;
  carouselData: Array<any>;
  onChangeSlide?: Function;
  customImgClass?: string;
  customClassName?: string;
}

function TGCarousel(props: IProps) {
  const { carouselData } = props;
  const [index, setIndex] = useState<any>(0);
  const isSecondary = props.variant === "secondary";
  const carouselRef = useRef<any>(null);
  useEffect(() => {
    const container = document.getElementById("tertiary") as any;
    document.documentElement.style.setProperty(
      "--caption-margin",
      `${container?.clientHeight + 24}px`
    );
  }, []);

  const handleSelect = (selectedIndex: Number) => {
    setIndex(selectedIndex);
    if (typeof props?.onChangeSlide !== "undefined") {
      props?.onChangeSlide(selectedIndex);
    }
  };

  const addDefaultImg = (ev: any) => {
    ev.target.src = "../assets/default.png";
  };

  const renderChildren = (subItem: any) => (
    <div className={styles[isSecondary ? "carousel-caption-container" : ""]}>
      <h3
        className={`${styles["carousel-header"]}  ${
          styles[props.customClassName ? props.customClassName : ""]
        } `}
      >
        {subItem.mainHeading}
      </h3>
      <div className={styles[`carousel-desc-container-${props.variant}`]}>
        <p
          className={`${styles["carousel-desc-" + props.variant]} ${
            styles["carousel-desc"]
          }`}
        >
          {subItem?.subHeading}
        </p>
      </div>
      {subItem?.hasButton && (
        <Link to={subItem.buttonUrl} target="_blank" aria-label="book-now opening in new tab">
          <TGButton
            label={subItem?.buttonText}
            variant="tertiary"
            buttonSize=""
          />
        </Link>
      )}
    </div>
  );

  const handleCarouselSlideChange = (currentIndex: number) => {
    let updatedIndex = currentIndex + 1;
    if (updatedIndex < carouselData.length) setIndex(updatedIndex);
    else {
      setIndex(0);
    }
  };

  return (
    <div
      className={`${styles[`container-${props.variant}`]} carousel-${
        props.variant
      }`}
      id={`${props.variant}`}
    >
      {isSecondary && renderChildren(carouselData[index])}
      <Carousel
        ref={carouselRef}
        interval={5000}
        activeIndex={index}
        onSelect={handleSelect}
        className={styles[`${props.variant}`]}
        id="carousel-arrow"
        touch={true}
      >
        {carouselData.map((item) => {
          return (
            <Carousel.Item>
              <Row>
                <Col md={12} xs={12} className={styles["carousel-col"]}>
                  <img
                    className={`${styles["img"]} ${
                      styles[`img-${props.variant}`]
                    } ${props.customImgClass}`}
                    src={item.imageURl}
                    alt={`carousel image ${item.mainHeading}`}
                    onError={addDefaultImg}
                  />
                  <div
                    className={styles["bgOverlay"]}
                    onClick={() => handleCarouselSlideChange(index)}
                  ></div>
                </Col>
              </Row>
              {!isSecondary && (
                <Carousel.Caption
                  className={styles[`carousel-caption-${props.variant}`]}
                >
                  <>{console.log("check", props.variant)}</>
                  {renderChildren(item)}
                </Carousel.Caption>
              )}
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default TGCarousel;
