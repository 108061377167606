import React, { Children, FC, ReactNode } from "react";
import styles from "./tg-card.module.scss";
import { Button, Card } from "react-bootstrap";
import TGButton from "../tg-button";

interface Props {
  data?: CardData;
  children?: ReactNode;
  cardClass: string;
  optionalCardClass?: string;
  title?: string;
  bodyClass: string;
  optionalSubtitleClass?: string;
  optionalTitleClass?: string;
  optionalTextClass?: string;
  optionalAlignmentClass?: string;
  cardImgSrc?: string;
  cardImgClass?: string;
  variant: string;
  cardButton: boolean;
}

interface CardData {
  imageUrl?: string;
  optionalHeading?: string;
  heading: string;
  description: string;
  buttonText: string;
  extraText?: string;
  type?: string;
}

const primaryCard = (item: Props) => (  
    <Card
      className={`${styles[item.cardClass]} ${
        styles[item.optionalCardClass ? item.optionalCardClass : ""]
      }`}
    >
      <Card.Body className={`${styles[item.bodyClass]}`}>
        {item.data?.optionalHeading !== "" && (
          <Card.Subtitle
            className={`${
              styles[
                item.optionalSubtitleClass ? item.optionalSubtitleClass : ""
              ]
            }`}
          >
            {item.data?.optionalHeading}
          </Card.Subtitle>
        )}
        <Card.Title
          className={`${
            styles[item.optionalTitleClass ? item.optionalTitleClass : ""]
          }`}
        >
          {item.data?.heading}
        </Card.Title>
        <Card.Text
          className={`${
            styles[item.optionalTextClass ? item.optionalTextClass : ""]
          }`}
        >
          {item.data?.description}
        </Card.Text>

        {item.cardButton && (
          <TGButton
            variant="secondary"
            buttonSize={"lg"}
            label={item.data?.buttonText||''}
          ></TGButton>
        )}
      </Card.Body>
    </Card>
  );


const renderCardTemplate = (item: Props) => {
  //current figma card both cases
  if (item.data && item.variant === "Primary") {
    return (
     primaryCard(item)
    );
    //Need to update based on vertical and horizontal images
  } else if (item.data && item.variant === "Secondary") {
    return (
      <Card className={`${styles[item.cardClass]}`}>
        <Card.Body className={`${styles[item.bodyClass]}`}>
          <Card.Img
            src={item.cardImgSrc? item.cardImgSrc : "./assets/sampleimage.jpg"}
            className={`${styles[item.cardImgClass ? item.cardImgClass : ""]}`}
          />
          <div
            className={`${
              styles[
                item.optionalAlignmentClass ? item.optionalAlignmentClass : ""
              ]
            }`}
          >
            {item.data.optionalHeading !== "" && (
              <Card.Subtitle
                className={`${
                  styles[
                    item.optionalSubtitleClass ? item.optionalSubtitleClass : ""
                  ]
                }`}
              >
                {item.data.optionalHeading}
              </Card.Subtitle>
            )}
            <Card.Title
              className={`${
                styles[item.optionalTitleClass ? item.optionalTitleClass : ""]
              }`}
            >
              {item.data.heading}
            </Card.Title>
            <Card.Text
              className={`${
                styles[item.optionalTextClass ? item.optionalTextClass : ""]
              }`}
            >
              {item.data.description}
            </Card.Text>
            {item.data.extraText !== "" && (
              <Card.Text>{item.data.extraText}</Card.Text>
            )}
          </div>

          {/* <Button className={`${styles[item.buttonClass]}`}>{item.data.buttonText}</Button> */}
        </Card.Body>
      </Card>
    );
    //This is in case the card template is not following both primary or secondary variants
  } else if (item.variant === "Teritiary") {
    return (
      <Card className={`${styles[item.cardClass]}`}>       
          {item.children}       
      </Card>
    );
  }
};

const TGCard: React.FC<Props> = (props: Props) => {
  return <>{renderCardTemplate(props)}</>;
};

export default TGCard;
